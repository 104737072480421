.login-form-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px 80px;
}

.login-form {
  width: 520px;
  height: 580px;
  margin-top: 50px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 68px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.forgot-form {
  width: 520px;
  height: 320px;
  margin-top: 50px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 68px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.reset-form {
  width: 520px;
  height: 320px;
  margin-top: 50px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 68px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.login-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.login-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;

  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.login-text {
  text-align: center;
  color: #000000;
  font-size: 17px;
  font-weight: 400;
}
.login-text-login {
  text-align: center;
  color: #000000;
  font-weight: 500;
  font-size: 30px;
}

.login-text-me {
  font-weight: 400;
}

.btn-login {
  background-color: #1cc300;
  border-radius: 30px;
  width: 180px;
  height: 40px;
  border-color: #1cc300;
  text-align: center;
}

.btn-forgot-request {
  background-color: #1cc300;
  border-radius: 30px;
  border-color: #1cc300;
  text-align: center;
}

.btn-center {
  text-align: center;
}

.btn-join {
  background-color: #0b4e00;
  border-radius: 30px;
  width: 180px;
  height: 40px;
  border-color: #0b4e00;
  text-align: center;
}
