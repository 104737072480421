@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter";
}
/* #root {
  overflow: hidden auto;
} */
#root > .App.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}
#root > .App {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}
main {
  max-width: 100%;
}
div {
  text-align: left;
}
.container {
  display: flex;
  width: 100%;
  max-width: 1600px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

h1,
h2,
h3,
a,
p,
span {
  font-family: inherit;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
hr,
figure,
p {
  margin: 0;
}

h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 69.5px;
  color: #000;
}

h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  color: #000;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #000;
}

p {
  font-size: 17px;
  font-weight: 400;
  color: #000;
}

a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
  transition: color 0.3s;
  text-decoration: none !important;
}

a:hover {
  color: #1cc400;
  text-decoration: none;
}

button {
  font-size: 16px;
  border-style: solid;
}

textarea,
input {
  font-size: 16px;
  border-style: solid;
}

.header-right .message-decorate {
  transform: rotate(45deg);
}

.swiper .swiper-button-prev,
.swiper .swiper-button-next {
  color: #0b4e00;
}

.swiper .swiper-button-next::after {
  padding: 18px 21px 18px 20px;
  font-size: 15px;
  border-radius: 50%;
  background-color: #0b4e0008;
}

.swiper .swiper-button-prev::after {
  padding: 18px 20px 17px 19px;
  font-size: 15px;
  border-radius: 50%;
  background-color: #0b4e0008;
}

.single-service form input::placeholder {
  color: #000;
}

.dashboard #simple-tabpanel-0 .css-19kzrtu,
.dashboard #simple-tabpanel-1 .css-19kzrtu,
.dashboard #simple-tabpanel-2 .css-19kzrtu,
.dashboard #simple-tabpanel-3 .css-19kzrtu {
  padding: 0 !important;
}

.dashboard #simple-tabpanels-0 .css-19kzrtu,
.dashboard #simple-tabpanels-1 .css-19kzrtu,
.dashboard #simple-tabpanels-2 .css-19kzrtu,
.dashboard #simple-tabpanels-3 .css-19kzrtu {
  padding: 0 !important;
}

.dashboard .MuiTabs-root button {
  transition: 0.3s;
  border-bottom: 1px solid transparent;
}

.dashboard .MuiTabs-root button:hover {
  color: #1cc300;
}

.dashboard .MuiTabs-root button:focus {
  outline: none;
  color: #1cc300;
  border-color: #1cc300;
}

.dashboard .MuiTabs-root button[aria-selected="true"] {
  color: #1cc300;
  border-color: #1cc300;
}

@media (max-width: 640px) {
  .dashboard .MuiTabs-scroller button.MuiButtonBase-root {
    max-width: 160px !important;
  }
  .dashboard .MuiTabs-scroller .MuiTabs-flexContainer {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.edit-profile .MuiTabs-scroller .MuiTab-root {
  border-radius: 15px;
  transition: color 0.3s, background-color 0.3s;
}

.edit-profile .MuiTabs-flexContainer {
  justify-content: space-around;
}

.edit-profile .MuiTabs-scroller .MuiTab-root:hover {
  color: #fff;
  background-color: #1cc300;
}

.edit-profile .MuiTabs-scroller .MuiTab-root:focus {
  outline: none;
}

.edit-profile .MuiTabs-scroller .MuiTab-root.Mui-selected {
  background-color: #1cc300;
  color: #fff;
}

.edit-profile .MuiButtonBase-root span.MuiTab-wrapper {
  text-transform: capitalize;
}

.credits .MuiTabs-root .MuiButtonBase-root {
  text-transform: capitalize;
  transition: all 0.3s;
  border: none;
  border-radius: 13px;
}

.credits .MuiTabs-root .MuiButtonBase-root:focus,
.credits .MuiTabs-root .MuiButtonBase-root:active {
  outline: none;
}

.credits .MuiTabs-root .MuiButtonBase-root:hover {
  color: #fff;
  background-color: #1cc300;
}

.credits .MuiTabs-root .MuiButtonBase-root.Mui-selected {
  color: #fff;
  background-color: #1cc300;
}

.login input::placeholder {
  font-family: "Inter";
}

.register input::placeholder {
  font-family: "Inter";
}

.login label,
.register label {
  font-family: "Inter";
}

.form-control {
  width: 100%;
  padding: 8px 16px;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: auto !important;
    flex: 1;
  }
}

.service-name input.form-control::placeholder {
  color: #959595;
}

.profile .gm-fullscreen-control,
.profile .gmnoprint {
  display: none;
}
.dashboard .gm-fullscreen-control,
.dashboard .gmnoprint {
  display: none;
}

.service-nav .MuiTabs-flexContainer {
  display: flex !important;
  flex-direction: column !important;
}

.edit-tab-content .MuiBox-root {
  padding: 0;
}

.edit-tab-content .MuiPaper-root {
  box-shadow: none;
}

.edit-tab-content {
  padding: 0 2rem 2rem;
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid #e9e9e9;
}
.send-msg {
  flex-direction: row-reverse !important;
  overflow-y: auto;
}
.send-msg > .MuiGrid-root:first-child {
  background-color: #1cc300;
  color: #fff;
}
.receive-msg > .MuiGrid-root:first-child {
  background-color: #f9f9f9;
}
.send-msg > .MuiGrid-root,
.receive-msg > .MuiGrid-root {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 8px;
}

.footer-input .MuiInput-underline::before {
  border-width: 0 !important;
}

/* Scrollbar width */
.scroll-container::-webkit-scrollbar {
  width: 6px;
}

/* Show scrollbar on hover */
.scroll-container:hover::-webkit-scrollbar {
  background-color: transparent;
}

/* Style scrollbar track */
.scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Style scrollbar thumb */
.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* Style scrollbar thumb on hover */
.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.ask-question .MuiFormControl-root .MuiInputBase-root::before {
  display: none;
}

.ask-question .MuiFormControl-root .MuiInputBase-root::after {
  display: none;
}

.ask-question .MuiFormControl-root .MuiInputBase-root {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0;
  border-bottom: none;
}

.ask-question .MuiFormControl-root textarea.MuiInputBase-input {
  padding-top: 12px;
  padding-bottom: 17px;
}

.ewqTBN {
  max-width: 100% !important;
  min-height: 150px;
  flex-direction: column;
  justify-content: center;
  border-color: #1cc400 !important;
}

.ewqTBN svg {
  fill: #1cc300 !important;
  stroke: #1cc300 !important;
}

.kyUZVo {
  flex: 0 !important;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
}

.kyUZVo .file-types {
  max-width: none !important;
  margin-top: 4px;
}

.page-404 {
  font-family: sans-serif;
  text-align: center;
}

.error-code {
  font-size: 10em;
  color: #ff0000;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
}

.MuiRating-sizeLarge > label,
.MuiRating-sizeLarge > span {
  font-size: 36px;
  margin-bottom: 0;
  color: #1cc300;
}

.filepond--credits {
  display: none;
}

.fade-enter {
  opacity: 0;
  visibility: hidden;
}

.fade-enter-active {
  opacity: 1;
  visibility: visible;
  transition: opacity 600ms;
}

.fade-exit {
  opacity: 1;
  visibility: visible;
}

.fade-exit-active {
  visibility: hidden;
  opacity: 0;
  transition: opacity 600ms;
}

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #1cc300;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
.MuiOutlinedInput-notchedOutline {
  border: none;
}
.filepond--drop-label {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  transition: all;
  cursor: pointer;
}
.filepond--drop-label:hover {
  border: 1px solid #2bef0a;
}
